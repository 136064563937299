import React from "react";
import ample from './../../assets/image/ample.png';
import AuthenLayout from "./../../components/layout/authen";
import { TermHeader } from "./../../components/layout/term-header";
import { Divider } from "antd";

const data = [
  {
    title: '1. PURPOSE OF NOTICE STATEMENT',
    p: [
      `The following is the privacy notice statement (“Privacy Notice”) of Ample Transfers Pte. Ltd.
      (collectively, “we”, “our”, or “us”) and sets forth our policies & practices in connection with personal
      data that we collect through our website, and any mobile sites, applications, or other mobile
      interactive features (collectively, the “Platform”).`,
      `Our Notice is to comply—and ensure that our employees comply—with the requirements of the
      Singapore Personal Data Protection Act (2012) (“PDPA”) and any other relevant privacy laws in the
      locations in which we operate.`,
      `In order to use the services provided on our Platform, you will have to share your personal data with
      us.`,
    ]
  },
  {
    title: '2. SCOPE',
    p: [
      `This Privacy Notice explains how we handle personal data you share with Ample Transfers.`,
      `“Personal data” is personally identifiable information that identifies you as an individual, such as
      your name, mailing address, email address, age range, and the like. Personal data is only obtained
      when you voluntarily provide the information to us. We use personal data to better understand your
      needs and interests and to provide you with better service. For any other purposes for which we
      have obtained your consent. We rely on you to have obtained consent from the relevant third-party
      (e.g., receiver, sender) for any personal information of these third-party that you have provided to
      us and/or will provide to us.`,
      `“Data Subjects” are Individuals accessing the Platform or who are registering to use the services on
      the Platform and who voluntarily provide personal data to us (“you” or “your”) are covered by this
      Privacy Notice.`
    ]
  },
  {
    title: '3. AMPLE TRANSFERS PRIVACY PRINCIPLES',
    p: [
      `Your privacy matters to us. Our business has been built on trust between our customers and
      ourselves. To preserve the confidentiality of all information you provide to us, we shall maintain the
      following privacy principles:`,
      {
        type: 'alphabet',
        data: [
          `we will first obtain your consent to collect, use, or disclose your personal data;`,
          `we only collect personal data that we believe to be relevant and necessary, in order to help us
          conduct our business;`,
          `we only collect personal data that we believe to be relevant and necessary, in order to help us
          conduct our business;`,
          `where your consent has been provided, your personal data may be transferred to third parties,
          including other Ample Transfers companies or agents, as may be advised to you, either within or
          outside Singapore, and as permitted by law. Any contracts with these third parties will include the
          necessary provisions to safeguard the personal data that is being transferred to them in accordance
          with the PDPA;`,
          `we may be required from time to time to disclose your personal information to governmental or
          judicial bodies or agencies or our regulators, if required to do so by law;`,
          `we shall take reasonable measures to ensure that your personal data in our possession or control
          is accurate and up-to-date;`,
          `we protect the personal data in our possession or under our control by making reasonable
          security arrangements to prevent unauthorised access, collection, use, disclosure, copying,
          modification, or disposal of such data`,
          `we may collect, use and disclose your personal data pursuant to an exception under the Personal
          Data Protection Act or as required/authorised under any other written law.`
        ]
      },
      `By maintaining our commitment to these principles, we will ensure that we respect the inherent
      trust that you place in us.`,
      `This Notice shall stipulate:`,
      {
        type: 'lama',
        data: [
          'our purposes of personal data collection;',
          'the important controls we employ for protecting personal data;',
          'the classes of persons we can transfer personal data to;',
          'the data access and correction right of customers;',
          'our Notice for data transfer;',
          'use of personal data in direct marketing;',
          'retention of personal data; and',
          'withdrawal of your consent.'
        ]
      }
    ]
  },
  {
    title: '4. PURPOSES OF DATA COLLECTION',
    p: [
      {
        type: 'alphabet',
        data: [
          `From time to time, it may be necessary for you to supply us your personal data in connection
          with the opening or continuation of accounts and the establishment or continuation of facilities or
          provision of opening or continuation of accounts and the establishment or continuation of facilities
          or provision of commercial services.`,
          `Failure to supply such personal data may result in our inability to open or continue accounts or
          establish or continue facilities or provide services to you.`,
          `We may also collect personal data in the ordinary course of the continuing our business
          relationship, for example, when you effect a payment transaction or avail any of our other services
          through the Platform.`,
          `Notification will be sent to you on the preferred currency that you have subscribed.`,
          `We, or authorised third parties, may use your personal data for any one of the following
          purposes:`
        ]
      },
      {
        type: 'lama',
        data: [
          `conducting credit checks and carrying out matching procedures at the time of application for credit
          and at the time of regular or special reviews which normally will take place at least once each
          calendar year;`,
          `creating and maintaining our credit assessments;`,
          `ensuring your ongoing credit worthiness;`,
          `designing services or related products for your use;`,
          `marketing services, promotional materials, or other services or products for which we may or may
          not be remunerated;`,
          `determining the amount of indebtedness owed to or by you;`,
          `enforcement of your obligations, including, without limitation, the collection of amounts
          outstanding from you`,
          `complying with the obligations, requirements or arrangements for disclosing and using data that
          apply to us including:`
        ]
      },
      {
        type: 'number',
        data: [
          'any law binding or applying to us existing currently and as may be amended, from time to time;',
          `any guidelines or guidance given or issued by any legal, regulatory, governmental, tax, law
          enforcement or other authorities, or self-regulatory or industry bodies or associations of financial
          services providers existing currently and in the future that apply to us; or
          `,
          `any present or future contractual or other commitment with local or foreign legal, regulatory,
          governmental, tax, law enforcement or other authorities, or self-regulatory or industry bodies or
          associations of financial services providers that is assumed by or imposed on us by reason of our
          financial, commercial, business or other interests or activities in or related to the jurisdiction of the
          relevant local or foreign legal, regulatory, governmental, tax, law enforcement or other authority, or
          self-regulatory or industry bodies or associations.`,
          `complying with any obligations, requirements, policies, procedures, measures or arrangements for
          sharing data and information within our intragroup companies, subsidiaries, or affiliates, and any
          other use of data and information in accordance with any group-wide programmes for compliance
          with sanctions or prevention or detection of money laundering, terrorist financing or other unlawful
          activities; or`,
          `other types of uses for personal data in connection with our business.`
        ]
      }
    ]
  },
  {
    title: '5. IMPORTANT CONTROLS EMPLOYED BY AMPLE TRANSFERS FOR PROTECTION OF PERSONAL DATA',
    p: [
      {
        type: 'alphabet',
        data: [
          `Personal data in our possession and under our control shall be kept confidential and private
          under encryption. We shall take reasonable technical and organisational precautions to prevent the
          loss, misuse or alteration of your personal data. We shall store all the personal data you provide on
          our secure servers;`,
          `Physical copies shall be under lock and key with logged access;`,
          ` Unfortunately, however, no data transmission over the internet or data storage system can be
          guaranteed to be 100% secure. Any losses of personal data shall be handled at the outset as per
          legislated requirements or guidelines. Should there be one, we shall make arrangements to notify
          the relevant internal & external stakeholders. Periodic updates will be arranged to notify on actions
          and remedies taken with the final solution to be shared, on a fair and equitable basis. The final
          handling decision lies with us, based on legal and regulatory priorities firstly followed by its social
          responsibility`
        ]
      }
    ]
  },
  {
    title: '6. CLASSES OF PERSONS WE CAN TRANSFER PERSONAL DATA',
    p: [
      `Ample Transfers may provide such personal data to the following third parties for the purposes set
      out in Section:`,
      {
        type: 'alphabet',
        data: [
          ` any agent, contractor or third-party service provider who provides administrative,
          telecommunications, computer, payment, debt collection or other services to it in connection with
          the operation of its business;`,
          `any other person or entity under a duty of confidentiality within our group companies which has
          to be in line with their nature of function on a need to know basis;`,
          `any person or entity to whom we are obliged or otherwise required to make disclosure under the
          requirements of any law binding on or applying to our relevant group company, or any disclosure
          under and for the purposes of any guidelines or guidance given or issued by any legal, regulatory,
          governmental, tax, law enforcement or other authorities, or self-regulatory or industry bodies or
          associations of financial services providers with which our relevant group company is expected to
          comply, or any disclosure pursuant to any contractual or other commitment of our relevant group
          company with local or foreign legal, regulatory, governmental, tax, law enforcement or other
          authorities, or self-regulatory or industry bodies or associations of financial services providers, all of 
          which may be existing currently and in the future applying to itself or its subsidiaries; or
          `,
          `any other person or entity (including its associated companies or affiliates) who had established
          or proposes to establish any business relationship with it or recipient of the data. In all instances we
          shall ensure that our contracts with such third parties shall stipulate that the third parties shall act in
          accordance with the PDPA with respect to your personal data.`
        ]
      }
    ]
  },
  {
    title: '7. ACCESS TO AND CORRECTION OF PERSONAL DATA',
    p: [
      '(a) You have the right:',
      {
        type: 'lama',
        data: [
          'to check whether we hold your personal data;',
          `to access and correct your personal data;`,
          `to request that we correct any of your personal data that may be inaccurate; and`,
          `to inquire about our policies and practices in relation to personal data and to be informed of the
          kind of personal data held by us.`
        ]
      },
      `(b) The above requests can be addressed to The Data Protection Officer (DPO). We may require you
      to verify your identity before we provide you access to your personal data.`,
      `(c) We may charge you a fee in accordance with our Schedule of Fees and Guidelines to access to
      your personal data.`
    ]
  },
  {
    title: `8. CONSEQUENCES OF NOT CONSENTING AND/OR PROVIDING PERSONAL DATA NECESSARY FOR
    TRANSACTION/SERVICE`,
    p: [
      `Please be noted that should you not consent and provide us with the relevant personal data to fulfil
      the “Purposes” of collection, use and disclosure of your personal information it may hinder our
      ability to continue to interact with you.`,
      `For Job Applicants: We may not be able to make a decision on your suitability for recruitment and
      employment or comply with the law and therefore, we may not be able to make an offer of
      employment.`,
      `For Visitors to the Office: We may not be able to allow you to enter the office premises
      For Customers: We may not be able to provide services, onboarding support and ongoing services
      support`,
      `For Website Users: We may not be able to fulfil the Purposes listed in the Website Privacy Policy.`
    ]
  },
  {
    title: '9. ACCURACY OF PERSONAL DATA',
    p: [
      `Ample Transfer shall make a reasonable effort to ensure that personal data collected by or on behalf
      of the organisation is accurate and complete. Ample Transfer may presume that personal data
      provided directly by the individual concerned is accurate in most circumstances. When in doubt,
      Ample Transfer may consider requiring the individual to make a verbal or written declaration that
      the personal data provided is accurate and complete`
    ]
  },
  {
    title: '10. DATA TRANSFER',
    p: [
      `Upon obtaining your consent, your personal data may be processed, kept, transferred or disclosed in
      and to any country by us and in accordance with the PDPA and the laws, rules, regulations, or
      governmental orders of that country.`,
    ]
  },
  {
    title: '11. USE OF DATA IN DIRECT MARKETING',
    p: [
      `Before we use your personal data in direct marketing, we will obtain your explicit consent (which
        includes an indication of no objection) to do so.`,
      `Nothing in this Privacy Notice shall limit your rights under the PDPA.`
    ]
  },
  {
    title: '12. RETENTION OF PERSONAL DATA',
    p: [
      `We retain your personal data for the period necessary to carry out the purposes outlined in this
      Privacy Notice unless a longer period is required or permitted by law`,
      `We will cease to retain your personal data or remove the means by which the data can be associated
      with you, as soon as it is reasonable to assume that such retention no longer serves the purposes for
      which the personal data were collected and are no longer necessary for legal or business purposes.`,
    ]
  },
  {
    title: '13. WITHDRAWAL OF CONSENT',
    p: [
      `You understand that Ample Transfers mainly provides financial services that are regulated in
      Singapore by the Monetary Authority of Singapore, and that we may collect your personal
      information for the purposes of conducting financial transactions with you. In this case, Ample
      Transfers’ collection of Personal Data is governed by the prescribed personal data regulations that
      are outlined in the MAS’ Payment Services Act 2019.`,
      `You may, at any time, request to delete your data collected by us and you have the right to withdraw
      your Consent (whether in part or in whole) at any time by contacting our Data Protection Officer
      (DPO) as indicated below at Clause 16.`,
      `You understand that the deletion of your data or withdrawal of your consent can and shall be carried
      out as long as it does not contravene the legislative regulatory obligations by which the usage of
      Ample Transfers’ website, platform, products, or services is subject to.`,
      `“Notify consequences of withdrawal” – Upon receipt of your written request to withdraw your
      consent, we may require reasonable time (depending on the complexity of the request and its
      impact on our relationship with you) for your request to be processed and for us to notify you of the
      consequences of us acceding to the same, including any legal consequences which may affect your
      rights and liabilities to us. In general, we shall seek to process and effect your request within fifteen
      (15) days of receiving it.`,
      `Whilst we respect your decision to withdraw your consent, please note that depending on the
      nature and extent of your request, we may not be in a position to process your newsletter
      subscription, address your outstanding queries and/or other activities as stated in the section under
      Purposes of Data Collection. Should you decide to cancel your withdrawal of consent, please inform
      us in writing through the Data Protection Officer.`,
    ]
  },
  {
    title: '14. COOKIES',
    p: [
      `We use cookies on our website. These enable us to understand how you use our website and
      services which, in turn, helps us to provide and improve our services. A cookie is a small data file
      sent from a website to your browser that is stored on your device. Cookies are used for a number of
      purposes including to store some of your viewing preference on our website.`,
      `Our website uses cookies to help us identify you from other users, track your usage of our services,
      and your website access preferences. We do or may use the following cookies:`,
      {
        type: 'alphabet',
        data: [
          `Strictly necessary cookies </br>
          These are cookies that are required for the operation of our website. They include, for example,
          cookies that enable you to log into secure areas of our website.
          `,
          `Analytical/performance cookies. </br>
          They allow us to recognise and count the number of visitors and to see how visitors move around
          our website when they are using it. This helps us to improve the way our website works, for
          example, by ensuring that users are finding what they are looking for easily.
          `,
          `Functionality cookies. </br>
          These are used to recognise you when you return to our website. This enables us to personalise our
          content for you, greet you by name and remember your preferences (for example, your choice of
          language or region).
          `
        ]
      },
      `You have the ability to accept or decline cookies by modifying the setting in your browser. If you
      would like to do this, please see the help menu of your browser. However, you may not be able to
      use all the interactive features of our website if cookies are disabled.`
    ]
  },
  {
    title: '15. CHANGES TO PRIVACY NOTICE',
    p: [
      `This Policy applies in conjunction with any other notices, contractual clauses and consent clauses
      that apply in relation to the collection, use and disclosure of your personal data by us.`,
      `We may revise this Policy from time to time without any prior notice. You may determine if any such
      revision has taken place by referring to the date on which this Policy was last updated. Your
      continued use of our services constitutes your acknowledgement and acceptance of such changes.`,
    ]
  },
  {
    title: '16. DATA PROTECTION OFFICER',
    p: [
      `You may contact our Data Protection Officer if you have any enquiries or feedback on our personal
      data protection policies and procedures, or if you wish to make any request, in the following
      manner:`,
      {
        type: 'normal',
        data: [
          `Email: pdpa@ampletransfers.com`,
          `Telephone: +65 6252 7327`,
          `Address: Ample Transfers Pte. Ltd.`,
          `246 Balestier Road, Singapore 329706`
        ]
      }
    ]
  }
]

const alphabet = [
  'a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n'
]

const number = [
  '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14'
]

const lama = [
  'i', 'ii', 'iii', 'iv', 'v', 'vi', 'vii', 'viii', 'ix', 'x', 'xii', 'xiii', 'xiv', 'xv'
]

export const PrivacyPolicyPage = () => {
  const _renderParaGraph = (p: any, index: number) => {
    if (typeof p === 'string') {
      return <div className={`${index > 1 ? 'mt-5' : ''}`} dangerouslySetInnerHTML={{__html: p}} />
    } else {
      switch(p?.type) {
        case 'lama': 
        return p?.data?.map((subText: any, subDex: number) => {
              return (<div key={subDex} className="mt-1 leading-[24px]" dangerouslySetInnerHTML={{__html: `${lama[subDex]}. ${subText}`}}></div>)
            })
        case 'number':
          return p?.data?.map((subText: any, subDex: number) => {
            return (<div key={subDex} className="mt-1 leading-[24px]" dangerouslySetInnerHTML={{__html: `${number[subDex]}. ${subText}`}}></div>)
          })
        case 'alphabet':
          return p?.data?.map((subText: any, subDex: number) => {
            return (<div key={subDex} className="mt-1 leading-[24px]" dangerouslySetInnerHTML={{__html: `(${alphabet[subDex]}) ${subText}`}}></div>)
          })
        case 'normal':
          return p?.data?.map((subText: any, subDex: number) => {
            return (<div key={subDex} className="mt-1 leading-[24px]" dangerouslySetInnerHTML={{__html: `${subText}`}}></div>)
          })
      }
    }
    return ''
  }

  return (
      <AuthenLayout>
        <TermHeader title="Privacy Policy" />
        <div className="flex flex-col items-center">
          <div className="max-w-[956px] mt-[40px] px-4 font-rale-way">
            {/* <div className="underline text-[36px]">Privacy Notice</div> */}
            {
              data.map((item, mainDex) => (
                <div className="mt-10" key={mainDex}>
                  <div>
                    <div className="text-[25px] font-[700] leading-8 text-pink-theme-1">{item.title}</div>
                    {item.p.map((text: any, index) => <div className="mt-4" key={index}>
                      {_renderParaGraph(text, index)}
                    </div>)}
                  </div>
                  {
                    mainDex < data?.length - 1 && (
                      <Divider className="my-8" />
                    )
                  }
                </div>
              ))
            }
            <div className="mb-[100px]">
            </div>
          </div>
        </div>
      </AuthenLayout>
  )
}